import React from "react";
import { graphql, Link } from "gatsby";
import Layout from "../components/Layout";
import ReactMarkdown from "react-markdown";

export default function PatternDetails({ data, location }) {
  return (
    <Layout location={location}>
      <div className="styled-grid">
        <div
          className="breadcrumbs d-flex align-items-center"
          style={{ marginBottom: "0" }}
        >
          <Link to="/patterns/" className="breadcrumb-link">
            Patterns
          </Link>
          <div className="divider">/</div>

          <div className="breadcrumb-item ms-space-300">
            {data.contentfulPatterns.name}
          </div>
        </div>
        <h1>{data.contentfulPatterns.name}</h1>

        <div className="markdown-content">
          {data.contentfulPatterns.content !== null && (
            <ReactMarkdown className="markdown-content">
              {data.contentfulPatterns.content.content}
            </ReactMarkdown>
          )}
        </div>
      </div>
    </Layout>
  );
}

export const query = graphql`
  query PatternPage($name: String) {
    contentfulPatterns(name: { eq: $name }) {
      name
      status
      description
      content {
        content
      }
    }
  }
`;
